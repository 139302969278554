export const Faq = [
  {
    title: "Quais são as linhas da Verde Campo?",
    text: "LACFREE, Natural Whey, Corpo e Mente, Kids, e Essencial.",
  },
  {
    title: "O Natural Whey ajuda a emagrecer?",
    text: "Depende da forma que o produto será usado, pois combinado com atividades físicas ele pode auxiliar no ganho de massa magra. O Natural Whey confere a saciedade, é rico em proteína e cálcio, além de ser zero gordura e adoçado naturalmente. Ele é o companheiro ideal para o café da manhã, lanches intermediários e pré ou pós treino. Reforçamos a importância de realizar uma dieta e acompanhamento com especialista.",
  },
  {
    title: "Por que o leite da Verde Campo é Certificado?",
    text: "Desenvolvemos 4 certificações que nos ajudam a manter a qualidade do leite utilizado na produção: BPP – boas práticas de produção; BPBEA – boas práticas em bem-estar animal; BPA – boas práticas ambientais; BPCS – boas práticas em conformidade social",
  },
  {
    title: "Por que os produtos são naturais? E como?",
    text: "Os nossos produtos são feitos apenas com ingredientes naturais: sem conservantes e sem aromas e corantes artificiais. Todos são de matéria prima de alta qualidade, como o leite fresco certificado pelo PAS (Programa de alimento seguro) e que dispõem de padrões superiores aos Europeus, garantindo a ausência de antibióticos, além da cor e aroma dos produtos obtidos pelo uso de produtos naturais, como a beterraba, cenoura, cacau e baunilha.",
  },
  {
    title: "Qual o conservante utilizado?",
    text: "Os nossos produtos são livres de conservantes! Utilizamos a Pectina como estabilizante natural, uma substancia extraída diretamente da casca da laranja.",
  },
  {
    title:
      "Vocês adicionam a enzima lactase ou tem um outro processo para os produtos LACFREE?",
    text: "Os produtos LACFREE tem a característica de ser zero lactose pois há adição da enzima lactase na fabricação do produto. Controlamos todos os parâmetros do processo para que a hidrólise/quebra da lactose seja favorecida e também é feito monitoramento analítico, em nosso laboratório interno, a cada lote fabricado. Para comprovar a eficiência de nossos monitoramentos, são realizadas análises em laboratórios externos a cada 6 meses. A enzima lactase é um ingrediente, o qual é avaliado a cada lote recebido, para que não existam dúvidas durante seu uso.",
  },
  {
    title: "Onde posso comprar?",
    text: "<a href='https://verdecampo.com.br/onde-comprar/'>Clique aqui e descubra os pontos de venda mais próximos da sua casa</a>.",
  },
  {
    title: "Qual é o diferencial da Linha kids?",
    text: "É a primeira linha infantil do Brasil sem conservantes! Utilizamos a Pectina como estabilizante natural, uma substancia extraída diretamente da casca da laranja. A nossa linha kids é feita apenas com ingredientes naturais. Tem redução de 40% em açúcares, é adoçada com açúcar orgânico e livre de conservantes. Pesquisamos e estudamos os melhores ingredientes para compor o iogurte ideal para o seu filho!",
  },
  {
    title: "Tem açúcar no natural whey?",
    text: "A linha Natural Whey é adoçada naturalmente com stévia.",
  },
  {
    title: "Tive problemas com um produto, quem pode me ajudar?",
    text: "Pode entrar em contato pelo e-mail: sac@verdecampo.com.br",
  },
  {
    title: "Diabéticos podem consumir os iogurtes LACFREE?",
    text: "Na linha LACFREE não há adição de açucares, porém contém a glicose e galactose, provenientes da quebra da lactose. Recomendamos consumi-lo sob orientação de um médico ou nutricionista.",
  },
  {
    title: "Vocês fazem parcerias com influenciadores?",
    text: "Para dúvidas sobre divulgação, é só entrar em contato com o nosso time de marketing pelo e-mail: marketing@verdecampo.com.br",
  },
  {
    title: "Quero revender os produtos de vocês, como funciona?",
    text: "Para disponibilizar produtos Verde Campo na sua loja, é só entrar em contato pelo email: sac@verdecampo.com.br. Nossa equipe ficará feliz em atende-lo!",
  },
]
